import React from 'react'
import parser from 'react-html-parser';
import PropTypes from 'prop-types';

/**
 * 
 * @param {string} indirizzo_url : Indirizzo url link
 * @param {string} testo : string HTML con grassetto
 * @param {string} nota : string HTML normale
 * @param {string} body : Component normale
 * @returns 
 */
function Collegamento({
    indirizzo_url,
    testo,
    nota,
    body
}){
    return(
        <div>
            <a href={indirizzo_url} 
            target="_blank"
            rel="noopener noreferrer">
                <strong>
                  {parser(testo)}
                </strong>
             {parser(nota)}
             {body}
            </a>
        </div>
    )
}

Collegamento.propTypes = {
    indirizzo_url : PropTypes.string.isRequired,
    testo : PropTypes.string,
    nota : PropTypes.string,
    body : PropTypes.any
}

export default Collegamento;