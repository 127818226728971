import React from 'react'
import './stile.css'
import Collegamento from '../basicComponents/Collegamento'

export default function RaccoltaDocumentazione(props){
    return(
        <div className="CollegamentiRendicontazione">
            {props.page==="IT"?
            <>
                <strong>Workflow della Prevenzione</strong>
                <div>
                    <Collegamento 
                        indirizzo_url="https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/BandoPrevenzione_Manuale.pdf" 
                        testo="Manuale Bando Prevenzione"    
                    />
                    <Collegamento 
                        indirizzo_url="https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/C_17_bandi_351_0_file.pdf" 
                        testo="Avviso pubblico per la presentazione e selezione di progetti di ricerca applicata “Salute - Ambiente - Biodiversità - Clima”"    
                    />
                    <Collegamento 
                        indirizzo_url="https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/Informativa%20privacy.pdf" 
                        testo="Informativa Privacy"    
                    />
                </div>
            </>
            :
            <>
                <strong>Prevention Workflow</strong>
                <div>
                    <Collegamento 
                        indirizzo_url="https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/BandoPrevenzione_Manuale.pdf" 
                        testo="Prevention Call Manual"    
                    />
                    <Collegamento 
                        indirizzo_url="https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/C_17_bandi_351_0_file.pdf" 
                        testo="Public notice for the presentation and selection of applied research projects “Health - Environment - Biodiversity - Climate”"    
                    />
                    <Collegamento 
                        indirizzo_url="https://s3.eu-south-1.amazonaws.com/documenti.cbim.it/Informativa%20privacy.pdf" 
                        testo="Privacy Policy"    
                    />
                </div>
            </>
            }
    </div>
    )
}